import { capitalize as _capitalize, startCase as _startCase } from 'lodash';

export const builderMixin = (resourceType) => {
  if (resourceType && !['View', 'Sheet', 'Workflow'].includes(resourceType)) {
    throw new Error(`Invalid resource type: ${resourceType}`);
  }

  return {
    methods: {
      async builderMixin__execute(action, resource, ...args) {
        try {
          const toPascalCase = str => _startCase(str).replaceAll(' ', '');

          const { [toPascalCase(action)]: Action } = await import(`./Actions/${toPascalCase(resourceType || _capitalize(this.resource))}/index.js`); // uses resourceType or this.resource if available in the component

          const strategy = new Action(this);
          return await strategy.execute(resource, ...args);
        } catch (error) {
          this.$rollbar.error('builderMixin__execute', error);

          this.$errorRescue(this, error, `builderMixin__execute/${action}`, { fallbackTranslationKey: 'errors.builder.couldntPerformAction' });

          return null;
        }
      },

      // syntax sugars
      builderMixin__archive(resource) {
        return this.builderMixin__execute('archive', resource);
      },

      builderMixin__changeLinkColor(resource, onDataChanged = () => { }) {
        return this.builderMixin__execute('changeLinkColor', resource, onDataChanged);
      },

      builderMixin__changePrimaryColumn(resource, onDataChanged = () => { }) {
        return this.builderMixin__execute('changePrimaryColumn', resource, onDataChanged);
      },

      builderMixin__changeSystemName(resource) {
        return this.builderMixin__execute('changeSystemName', resource);
      },

      builderMixin__create() {
        return this.builderMixin__execute('create');
      },

      builderMixin__delete(resource) {
        return this.builderMixin__execute('delete', resource);
      },

      builderMixin__duplicate(resource) {
        return this.builderMixin__execute('duplicate', resource);
      },

      builderMixin__editFields(resource, onDataChanged = () => { }) {
        return this.builderMixin__execute('editFields', resource, onDataChanged);
      },

      builderMixin__exportSystemSchema(resource) {
        return this.builderMixin__execute('exportSystemSchema', resource);
      },

      builderMixin__fillDummyData(resource) {
        return this.builderMixin__execute('fillDummyData', resource);
      },

      builderMixin__moveToCategory(resource, newCategory) {
        return this.builderMixin__execute('moveToCategory', resource, newCategory);
      },

      builderMixin__rename(resource) {
        return this.builderMixin__execute('rename', resource);
      },

      builderMixin__unarchive(resource) {
        return this.builderMixin__execute('unarchive', resource);
      },
    },
  };
};
